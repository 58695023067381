import React, {useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgroundSection"
import MainContent from "../components/atoms/MainContent"
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/atoms/Paragraph"
import {css} from "@emotion/core";

const ContainerStyles = css`
  padding: 80px;
  text-align: left;
  @media(max-width: 767px) {
    padding: 30px;
  }
`

const FormContainerStyles = css`
  margin-top: 50px;
`

const NameAndEmailContainerStyles = css`
  display: flex;
  margin: 0 0 30px 0;
`

const CommentContainerStyles = css`
  display: flex;
  margin: 0 0 30px 0;
`

const fieldStyles = `
  position: relative;
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    box-shadow: none;
    box-sizing: content-box;
  }
  textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    width: 100%;
    font-size: 1rem;
    box-shadow: none;
    box-sizing: content-box;
    resize: none;
  }
  label {
    color: #9e9e9e;
    position: absolute;
    top: 0;
    font-size: 1rem;
    cursor: text;
    transform: translateY(12px);
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  }
  &:hover input {
    border-bottom: 1px solid #26a69a;
  }
  &:hover textarea {
    border-bottom: 1px solid #26a69a;
  }
  &:hover label {
    color: #26a69a;
    transform: translateY(-10px);
  }
`

const NameFieldStyles = css(fieldStyles, `width: 280px; margin-right: 40px;`)

const EmailFieldStyles = css(fieldStyles, `width: 280px;`)

const CommentFieldStyles = css(fieldStyles, `
  width: 600px;
  label {
    top: -15px;
  }
`)

const SendButtonContainerStyles = css`
  text-align: center;
`

const SendButtonStyles = css`
  margin-right: 10px;
  padding: 5px 30px;
  font-size: 18px;
  font-family: 'Noto Sans JP', sans-serif;
  color: #ffffff;
  background-color: #2bbbad;
  outline: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: .2s ease-out;
  &:hover {
    background-color: #26a69a;
  }
`

const ContactPage = () => {
  const [status, setStatus] = useState("")
  const [textareaRows, setTextareaRows] = useState(3)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  }

  const handleChangeComment = (e) => {
    setComment(e.target.value)
    const scrollHeight = e.target.scrollHeight
    const offsetHeight = e.target.offsetHeight
    if (textareaRows <= 10 && scrollHeight > offsetHeight){
      setTextareaRows(textareaRows + 1)
    }
  }

  return (
    <Layout>
      <BackgroundSection>
        <SEO title="Contact Page"/>
        <MainContent>
          <div css={ContainerStyles}>
            <Heading level={2}>連絡先</Heading>
            <br/>
            <Paragraph>
              サイトをご覧いただきありがとうございます。もし私やこのサイトに
              ついてコメントがありましたら下記フォームをご利用下さい。
            </Paragraph>
            <div css={FormContainerStyles}>
              <form method="POST" action="https://formspree.io/moqnzpwz" onSubmit={submitForm}>
                <div css={NameAndEmailContainerStyles}>
                  <div css={NameFieldStyles}>
                    <input id="name" name="name" type="text" value={name} onChange={e => setName(e.target.value)} required/>
                    { !name && <label htmlFor="name">Name</label> }
                  </div>
                  <div css={EmailFieldStyles}>
                    <input id="email" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                    { !email && <label htmlFor="email">Email</label> }
                  </div>
                </div>
                <div css={CommentContainerStyles}>
                  <div css={CommentFieldStyles}>
                    <textarea id="comment" name="comment" rows={textareaRows} onChange={handleChangeComment} required></textarea>
                    { !comment && <label htmlFor="comment">Comment</label> }
                  </div>
                </div>
                <div css={SendButtonContainerStyles}>
                  {status === "SUCCESS" ? <Paragraph>Thanks!</Paragraph> :
                    <button css={SendButtonStyles} type="submit">Send</button>}
                  {status === "ERROR" && <Paragraph>Ooops! There was an error.</Paragraph>}
                </div>
              </form>
            </div>
          </div>
        </MainContent>
      </BackgroundSection>
    </Layout>
  )
}

export default ContactPage
